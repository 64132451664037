import PulseLoader from "react-spinners/PulseLoader";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const LoadingSpinner = ({ isLoading }) => {
  return (
    <div style={style}>
      <PulseLoader loading={isLoading} color="#00adef" />
    </div>
  );
};

export default LoadingSpinner;
