import LoadingSpinner from "./components/spinner";
import Survey from "./components/survey";
import useSurvey from "./hooks/useSurvey";

const App = () => {
  const { isLoading, buildModel } = useSurvey();

  return isLoading ? (
    <LoadingSpinner loading={isLoading} />
  ) : (
    <Survey model={buildModel()} />
  );
};

export default App;
