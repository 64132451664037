import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Model, surveyLocalization, surveyStrings } from 'survey-core';

const useSurvey = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [survey, setSurvey] = useState();
  const [error, setError] = useState();
  const [searchParams] = useSearchParams();

  const uuid = searchParams.get('t');
  const origin = searchParams.get('origin');

  const serverUrl = process.env.REACT_APP_SERVER_URL || '';

  useEffect(() => {
    (async () => {
      const survey = await fetchSurvey();
      setSurvey(survey);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSurvey = async () => {
    try {
      setIsLoading(true);
      if (uuid) {
        const response = await axios.get(
          `${serverUrl}/api/surveys/?uuid=${uuid}`,
        );
        return response.data;
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const saveSurveyResult = async (data) => {
    try {
      const notas = [];
      for (let key in data) {
        if (key.indexOf('siscom_') === 0) {
          const parts = key.split('_');
          notas.push({ idPergunta: +parts[1], nota: data[key] });
        }
      }

      const result = {
        uuid,
        dataResposta: Date.now(),
        origemResposta: origin,
        notas: notas,
        solicitacaoAtendida: data.solicitacao_atendida,
        comentario: null //data.comentario,
      };

      return axios.post(`${serverUrl}/api/surveys/${survey.id}/result`, result);
    } catch (err) {
      setError(err);
    }
  };

  const buildModel = () => {
    const model = new Model();
    model.title = 'CESAN - Pesquisa de satisfação';
    model.logo = `${process.env.PUBLIC_URL}/logo192.png`;
    model.logoWidth = 90;
    model.logoHeight = 90;

    const locale = surveyLocalization.locales['en'];
    locale.completeText = 'Finalizar';
    locale.requiredError = 'Resposta obrigatória.';
    locale.emptySurvey = 'Não foi possível exibir a pesquisa.';
    locale.ratingOptionsCaption = 'Clique aqui para avaliar...';

    if (survey) {
      model.description = `Solicitação: ${survey.numeroSs} | Serviço: ${survey.descricaoServico}`;

      const isAnswered = survey.dataResposta;
      if (isAnswered) {
        surveyStrings.emptySurvey = 'Pesquisa já respondida!';
      }

      const isExpired = survey.dataLimiteResposta
        ? new Date() > new Date(survey.dataLimiteResposta)
        : false;
      if (!isAnswered && isExpired) {
        surveyStrings.emptySurvey = 'Pesquisa expirada!';
      }

      const isValid = !isAnswered && !isExpired;
      if (isValid) {
        const page = model.addNewPage('pesquisa_satisfacao');
        const panel = page.addNewPanel('perguntas');
        panel.title = 'Todos os campos marcados com (*) são obrigatórios.';
        panel.description =
          'Nível de satisfação: 1 – Muito Insatisfeito / 2 – Insatisfeito / 3 – Neutro / 4 – Satisfeito / 5 – Muito satisfeito';

        const atendida = panel.addNewQuestion(
          'radiogroup',
          'solicitacao_atendida',
        );
        atendida.choices = [
          {
            value: true,
            text: 'Sim',
          },
          {
            value: false,
            text: 'Não',
          },
        ];
        atendida.title = 'Sua solicitação foi atendida?';
        atendida.colCount = 0;
        atendida.isRequired = true;

        const perguntas = survey.perguntas.sort((a, b) => a.ordem - b.ordem);

        for (const p of perguntas) {
          const siscom = panel.addNewQuestion('rating', `siscom_${p.idOrigem}`);
          siscom.title = p.titulo;
          siscom.description = p.descricao;
          siscom.rateMin = 1;
          siscom.rateMax = 5;
          siscom.isRequired = true;
        }

        /*
        const comentario = panel.addNewQuestion('comment', 'comentario');
        comentario.title = 'Gostaria de fornecer detalhes? (opcional)';
        comentario.placeholder = 'No máximo 5000 caracteres.';
        comentario.maxLength = 5000;
        */

        model.completedHtml =
          '<h3>Obrigado por nos ajudar a construir uma CESAN cada vez mais eficiente!</h3>';

        model.onComplete.add((sender) => {
          saveSurveyResult(sender.data);
        });
      }
    }

    return model;
  };

  return {
    isLoading,
    buildModel,
    error,
  };
};

export default useSurvey;
