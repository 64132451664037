import { StylesManager } from 'survey-core';
import 'survey-core/defaultV2.min.css';
import { Survey as SurveyJs } from 'survey-react-ui';

StylesManager.applyTheme('defaultV2');

const Survey = ({ model }) => {
  return <SurveyJs model={model} />;
};

export default Survey;
